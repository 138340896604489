import React, { useEffect, useState } from 'react';
import axios from '../../axios';
import {
  Container,
  Typography,
  Button,
  Modal,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Alert,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Card,
  CardContent,
  AppBar,
  Toolbar,
  CssBaseline,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Dashboard = ({ onLogout }) => {
  const [ips, setIps] = useState([]);
  const [newIp, setNewIp] = useState('');
  const [clientName, setClientName] = useState('');
  const [clientCnpjCpf, setClientCnpjCpf] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [status, setStatus] = useState('Ativo');
  const [backendSubdomain, setBackendSubdomain] = useState('');
  const [frontendSubdomain, setFrontendSubdomain] = useState('');
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [currentIp, setCurrentIp] = useState(null);
  const [alert, setAlert] = useState({ open: false, severity: '', message: '' });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleEditOpen = (ip) => {
    setCurrentIp(ip);
    setEditOpen(true);
  };
  const handleEditClose = () => setEditOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('ips/ips');
        setIps(response.data);
      } catch (error) {
        console.error("Erro ao buscar IPs: ", error);
      }
    };
    fetchData();
  }, []);

  const addIp = async () => {
    try {
      const response = await axios.post('/ips/add-ip', { 
        ip_address: newIp, 
        client_name: clientName, 
        client_cnpj_cpf: clientCnpjCpf, 
        client_email: clientEmail, 
        status, 
        backend_subdomain: backendSubdomain, 
        frontend_subdomain: frontendSubdomain 
      });
      setIps([...ips, response.data[0]]);
      setNewIp('');
      setClientName('');
      setClientCnpjCpf('');
      setClientEmail('');
      setStatus('Ativo');
      setBackendSubdomain('');
      setFrontendSubdomain('');
      handleClose();
      setAlert({ open: true, severity: 'success', message: 'IP adicionado com sucesso!' });
    } catch (e) {
      console.error('Erro ao adicionar IP: ', e);
      setAlert({ open: true, severity: 'error', message: e.response.data.error });
    }
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setCurrentIp({ ...currentIp, [name]: value });
  };

  const editIp = async () => {
    try {
      const { id, ip_address, client_name, client_cnpj_cpf, client_email, status, backend_subdomain, frontend_subdomain } = currentIp;
      const response = await axios.put(`/ips/update-ip/${id}`, { 
        ip_address, 
        client_name, 
        client_cnpj_cpf, 
        client_email, 
        status, 
        backend_subdomain, 
        frontend_subdomain 
      });
  
      if (response.status === 200) {
        // Sucesso na atualização
        setIps(ips.map(ip => ip.id === id ? { ...ip, ip_address, client_name, client_cnpj_cpf, client_email, status, backend_subdomain, frontend_subdomain } : ip));
        setAlert({ open: true, severity: 'success', message: 'IP atualizado com sucesso!' });
      } else {
        setAlert({ open: true, severity: 'error', message: 'Erro ao atualizar IP: Resposta inesperada do servidor' });
      }
  
      handleEditClose();
    } catch (e) {
      console.error('Erro ao atualizar IP: ', e);
      setAlert({ open: true, severity: 'error', message: e.response?.data?.error || 'Erro desconhecido' });
    }
  };

  const deleteIp = async (ip_address) => {
    try {
      await axios.delete(`/ips/delete-ip/${ip_address}`);
      setIps(ips.filter(ip => ip.ip_address !== ip_address));
      setAlert({ open: true, severity: 'success', message: 'IP deletado com sucesso!' });
    } catch (e) {
      console.error('Erro ao deletar IP: ', e);
      setAlert({ open: true, severity: 'error', message: e.response.data.error });
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({ ...alert, open: false });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CssBaseline />
      <AppBar position="static" color="default">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Dashboard
          </Typography>
          <Button variant="contained" color="secondary" onClick={onLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      <Container sx={{ mt: 4, minWidth: 1400 }}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom>
              IPs Autorizados
            </Typography>
            <Button variant="contained" color="primary" onClick={handleOpen} sx={{ mb: 2 }}>
              Adicionar IP
            </Button>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Endereço IP</TableCell>
                    <TableCell>Nome do Cliente</TableCell>
                    <TableCell>CNPJ/CPF</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Token</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Backend Subdomain</TableCell>
                    <TableCell>Frontend Subdomain</TableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ips.map((ip) => (
                    <TableRow key={ip.ip_address} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {ip.ip_address}
                      </TableCell>
                      <TableCell>{ip.client_name}</TableCell>
                      <TableCell>{ip.client_cnpj_cpf}</TableCell>
                      <TableCell>{ip.client_email}</TableCell>
                      <TableCell>{ip.token}</TableCell>
                      <TableCell>
                        <Select
                          value={ip.status}
                          onChange={(e) => {
                            const updatedStatus = e.target.value;
                            setIps(ips.map(i => i.ip_address === ip.ip_address ? { ...i, status: updatedStatus } : i));
                          }}
                        >
                          <MenuItem value="Ativo">Ativo</MenuItem>
                          <MenuItem value="Bloqueado">Bloqueado</MenuItem>
                          <MenuItem value="Inativo">Inativo</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>{ip.backend_subdomain}</TableCell>
                      <TableCell>{ip.frontend_subdomain}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleEditOpen(ip)} color="primary">
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => deleteIp(ip.ip_address)} color="secondary">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>

        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <Typography variant="h6" component="h2">
              Adicionar Novo IP
            </Typography>
            <TextField
              label="Endereço IP"
              variant="outlined"
              value={newIp}
              onChange={(e) => setNewIp(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Nome do Cliente"
              variant="outlined"
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="CNPJ/CPF"
              variant="outlined"
              value={clientCnpjCpf}
              onChange={(e) => setClientCnpjCpf(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              variant="outlined"
              value={clientEmail}
              onChange={(e) => setClientEmail(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Backend Subdomain"
              variant="outlined"
              value={backendSubdomain}
              onChange={(e) => setBackendSubdomain(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Frontend Subdomain"
              variant="outlined"
              value={frontendSubdomain}
              onChange={(e) => setFrontendSubdomain(e.target.value)}
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="Status"
              >
                <MenuItem value="Ativo">Ativo</MenuItem>
                <MenuItem value="Bloqueado">Bloqueado</MenuItem>
                <MenuItem value="Inativo">Inativo</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={addIp}>
              Adicionar IP
            </Button>
          </Box>
        </Modal>

        <Modal open={editOpen} onClose={handleEditClose}>
          <Box sx={style}>
            <Typography variant="h6" component="h2">
              Editar IP
            </Typography>
            <TextField
              label="Endereço IP"
              name="ip_address"
              variant="outlined"
              value={currentIp?.ip_address || ''}
              onChange={handleEditChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Nome do Cliente"
              name="client_name"
              variant="outlined"
              value={currentIp?.client_name || ''}
              onChange={handleEditChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="CNPJ/CPF"
              name="client_cnpj_cpf"
              variant="outlined"
              value={currentIp?.client_cnpj_cpf || ''}
              onChange={handleEditChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              name="client_email"
              variant="outlined"
              value={currentIp?.client_email || ''}
              onChange={handleEditChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Backend Subdomain"
              name="backend_subdomain"
              variant="outlined"
              value={currentIp?.backend_subdomain || ''}
              onChange={handleEditChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Frontend Subdomain"
              name="frontend_subdomain"
              variant="outlined"
              value={currentIp?.frontend_subdomain || ''}
              onChange={handleEditChange}
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={currentIp?.status || ''}
                onChange={handleEditChange}
                label="Status"
              >
                <MenuItem value="Ativo">Ativo</MenuItem>
                <MenuItem value="Bloqueado">Bloqueado</MenuItem>
                <MenuItem value="Inativo">Inativo</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={editIp}>
              Salvar Alterações
            </Button>
          </Box>
        </Modal>

        <Snackbar
          open={alert.open}
          autoHideDuration={5000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={handleAlertClose} severity={alert.severity} sx={{ width: '100%' }}>
            {alert.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default Dashboard;
